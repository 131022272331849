import { Button, Grid, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { CruiseSearchFilters } from '../../models/CruiseSearchFilters';
import { PredefinedSearchButton } from '../predefined-search-button/predefined-search-button';
import { SectionBox } from '../section-box/section-box';
import { SectionTitle } from '../section-title/section-title';
import './home-page-quick-search-buttons.scss';
import aftImage from './images/aft.webp';
import beachImage from './images/beach.webp';
import caribbeanVideo from './images/caribbean.webm';
import croatiaImage from './images/croatia.webp';
import dubaiImage from './images/dubai.webp';
import familyImage from './images/family.webp';
import lighthouseImage from './images/lighthouse.webp';
import santoriniImage from './images/santorini.webp';
import santorini2Image from './images/santorini2.webp';
import fantasiaShip from './images/ship-fantasia.webp';
import operaShip from './images/ship-opera.webp';
import toscanaShip from './images/ship-toscana.webp';
import worldeuropaShip from './images/ship-world-europa.webp';
import spaImage from './images/spa.webp';
import summerImage from './images/summer.webp';
import worldeuropaImage from './images/worldeuropa.webp';
import xmasImage from './images/xmas.webp';

import { useNavigate } from 'react-router-dom';
import { useTracking } from '../../hooks/useTracking';

const TILE_IMAGES: Record<string, string> = {
  aftImage,
  santoriniImage,
  summerImage,
  santorini2Image,
  lighthouseImage,
  xmasImage,
  beachImage,
  spaImage,
  croatiaImage,
  worldeuropaImage,
  familyImage,
  fantasiaShip,
  operaShip,
  worldeuropaShip,
  toscanaShip,
  dubaiImage
};

const TILES = __CONFIG__.quickSearch.home.map((tile) => ({
  filter: new CruiseSearchFilters(tile.key),
  image: TILE_IMAGES[`${tile.image}Image`],
  label: `home.quick-search-button.${tile.key}`,
  name: tile.key
}));

const SHIP_TILES = __CONFIG__.homePageHighlights.ships.map((tile) => ({
  filter: new CruiseSearchFilters(tile.key),
  image: TILE_IMAGES[`${tile.image}Ship`],
  label: `home.quick-search-button.${tile.key}`,
  name: tile.key,
  url: tile.url
}));

const VIDEO_TILE = {
  video: caribbeanVideo,
  label: 'home.quick-search-button.caribbean',
  name: 'caribbean-msc',
  filter: 'caribbean-msc'
};

export const HomePageQuickSearchButtons: React.FC<{ updateSearchFilters: (f: CruiseSearchFilters) => void }> = (
  props
) => {
  const { t } = useTranslation('common');
  const theme = useTheme();
  const isLayoutSmall = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });
  const { trackEvent } = useTracking();
  const navigate = useNavigate();

  const navigateToHighlightedShipUrl = (label: string, url: string) => () => {
    trackEvent('homepage-ship-highlight', { label: label });
    window.open(url, '_blank');
  };

  const onVideoTileClick = () => {
    trackEvent('predefined_search', { label: VIDEO_TILE.name });
    props.updateSearchFilters(new CruiseSearchFilters(VIDEO_TILE.filter));
    navigate({
      pathname: `/search/${VIDEO_TILE.name}`
    });
  };

  return (
    <>
      <SectionBox>
        <Box className="home-page-quick-search-buttons">
          <Stack spacing={2} alignItems={`${isLayoutSmall ? 'flex-start' : 'center'}`} marginBottom={'2rem'}>
            <SectionTitle title={t('home.predefined-search-title')} />
            <Grid
              container
              textAlign={`${isLayoutSmall ? 'left' : 'center'}`}
              marginLeft={{ xs: '-24px !important', md: '-48px !important' }}
              marginTop={{ xs: '-12px !important', md: '-12px !important' }}
              columnSpacing={3}
              rowSpacing={3}
            >
              <Grid item xs={12} sm={12} md={4} lg={4} key={VIDEO_TILE.label}>
                <Button
                  variant="contained"
                  focusRipple
                  sx={{
                    position: 'relative',
                    overflow: 'hidden',
                    borderRadius: 2,
                    cursor: 'pointer',
                    minHeight: '600px !important',
                    maxHeight: 'calc(100% - 3px)',
                    '& video': {
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover'
                    }
                  }}
                  onClick={onVideoTileClick}
                >
                  <video src={VIDEO_TILE.video} loop muted autoPlay playsInline />
                  <Box
                    sx={{
                      position: 'absolute',
                      bottom: 0,
                      left: 0,
                      width: '100%',
                      color: 'white',
                      backgroundColor: 'rgba(0, 0, 0, 0.5)',
                      padding: '8px 16px',
                      textAlign: 'center'
                    }}
                  >
                    <Typography component="span" variant="h2" color="inherit">
                      {t(VIDEO_TILE.label)}
                    </Typography>
                  </Box>
                </Button>
              </Grid>
              <Grid item xs={12} sm={12} md={8} lg={8}>
                <Grid container spacing={3}>
                  {TILES.map((tile, index) => (
                    <Grid item xs={12} sm={6} md={6} lg={6} key={tile.label}>
                      <PredefinedSearchButton
                        image={tile.image}
                        label={t(tile.label)}
                        name={tile.name}
                        searchFilters={tile.filter}
                        showLabel={true}
                        updateSearchFilters={props.updateSearchFilters}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Stack>

          {SHIP_TILES && SHIP_TILES.length > 0 && (
            <Stack spacing={2} alignItems={`${isLayoutSmall ? 'flex-start' : 'center'}`}>
              <SectionTitle title={t('home.ship-highlight-title')} />
              <Grid
                container
                textAlign={`${isLayoutSmall ? 'left' : 'center'}`}
                columnSpacing={3}
                rowSpacing={1}
                marginLeft={{ xs: '-24px !important', md: '-48px !important' }}
              >
                {SHIP_TILES.map((tile) => (
                  <Grid item xs={12} sm={6} md={4} key={tile.label}>
                    <PredefinedSearchButton
                      image={tile.image}
                      label={t(tile.label)}
                      name={tile.name}
                      searchFilters={tile.filter}
                      showLabel={false}
                      aspectRatio={'1'}
                      hoverAnimationEnabled={false}
                      updateSearchFilters={props.updateSearchFilters}
                      onButtonClick={navigateToHighlightedShipUrl(tile.name, tile.url)}
                    />
                  </Grid>
                ))}
              </Grid>
            </Stack>
          )}
        </Box>
      </SectionBox>
    </>
  );
};
