import { Box, Stack, Theme } from '@mui/material';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { Footer } from './components/footer/footer';
import { Header } from './components/header/header';
import { TrackingWithConsent } from './components/tracking-with-consent/tracking-with-consent';
import { CheckoutApiProvider } from './contexts/checkout-api.context';
import { SearchApiProvider } from './contexts/search-api.context';
import { withMediaQuery } from './hooks/withMediaQuery';
import { BookingState } from './models/BookingState';
import { BrowserStorage, STORAGE_KEY_BOOKING } from './models/BrowserStorage';
import { CruiseSearchFilters } from './models/CruiseSearchFilters';
import { StateDto } from './models/State';
import { Page404 } from './pages/404';
import { AboutPage } from './pages/about/about-page';
import { BlogPage } from './pages/blog/blog-page';
import { BookingPage } from './pages/booking/booking-page';
import { CheckoutPage } from './pages/checkout/checkout-page';
import { ContactPage } from './pages/contact/contact-page';
import { FAQPage } from './pages/faq/faq-page';
import { FavoritePage } from './pages/favorite/favorite-page';
import { HomePage } from './pages/home/home-page';
import { CostaToscanaAldeaLanding } from './pages/landings/costa-toscana-aldea';
import { CostaToscanaDubaiLanding } from './pages/landings/costa-toscana-dubai-landing';
import { OfferPage } from './pages/offer/offer-page';
import { PrivacyPolicyPage } from './pages/privacy-policy';
import { FilterPage } from './pages/search/filter-page';
import { SearchPage } from './pages/search/search-page';
import { TermsAndConditionsPage } from './pages/terms-and-conditions';
import { MessengerFAB } from './components/messenger-fab/messenger-fab';

interface AppProps extends WithTranslation {
  isLayoutSmall: boolean;
  isLayoutMedium: boolean;
}

class App extends React.Component<AppProps, StateDto> {
  topRef: React.RefObject<HTMLDivElement> = React.createRef();
  storage = BrowserStorage.getInstance();

  constructor(props: AppProps) {
    super(props);

    const filterParams = new URLSearchParams(window.location.search).get('f');
    const savedFilter = filterParams; //||this.storage.getItem(STORAGE_KEY_FILTER);
    const savedBooking = this.storage.getItem(STORAGE_KEY_BOOKING);
    this.state = {
      cruiseSearchFilters: new CruiseSearchFilters(savedFilter),
      booking: new BookingState(savedBooking)
    };
  }

  updateSearchFilters = (filters: CruiseSearchFilters) => {
    this.setState({
      cruiseSearchFilters: filters
    });
    filters.store();
  };

  updateBooking = (booking: BookingState) => {
    this.setState({
      booking: booking
    });
    booking.store();
  };

  scrollToTop = () => {
    setTimeout(() => this.topRef.current?.scrollIntoView({ behavior: 'smooth' }), 50);
  };

  render() {
    return (
      <Box>
        <SearchApiProvider>
          <CheckoutApiProvider>
            <Router>
              <TrackingWithConsent />
              <Box>
                <Stack spacing={0} sx={{ minHeight: this.props.isLayoutSmall ? 'calc(100vh - 100px)' : '' }}>
                  <Header
                    searchFilters={this.state.cruiseSearchFilters}
                    updateSearchFilters={this.updateSearchFilters}
                    scrollToTop={this.scrollToTop}
                  />
                  <Box
                    className={`${this.props.isLayoutMedium ? 'home-page-rounded-top' : 'home-page-top'}`}
                    sx={{ bgcolor: '#fff' }}
                    ref={this.topRef}
                  >
                    <Routes>
                      <Route
                        path="/"
                        element={
                          <HomePage
                            scrollToTop={this.scrollToTop}
                            searchFilters={this.state.cruiseSearchFilters}
                            updateSearchFilters={this.updateSearchFilters}
                          />
                        }
                      />
                      <Route
                        path="/filter/*"
                        element={
                          <FilterPage
                            searchFilters={this.state.cruiseSearchFilters}
                            scrollToTop={this.scrollToTop}
                            updateSearchFilters={this.updateSearchFilters}
                          />
                        }
                      />
                      <Route
                        path="/search/:filters"
                        element={
                          <SearchPage
                            searchFilters={this.state.cruiseSearchFilters}
                            scrollToTop={this.scrollToTop}
                            updateBooking={this.updateBooking}
                            updateSearchFilters={this.updateSearchFilters}
                          />
                        }
                      />
                      <Route
                        path="/search"
                        element={
                          <SearchPage
                            searchFilters={this.state.cruiseSearchFilters}
                            scrollToTop={this.scrollToTop}
                            updateBooking={this.updateBooking}
                            updateSearchFilters={this.updateSearchFilters}
                          />
                        }
                      />
                      <Route
                        path="/book/:cruiseLine/:cruiseGroupId/:stepParam"
                        element={
                          <BookingPage
                            bookingState={this.state.booking}
                            scrollToTop={this.scrollToTop}
                            cruiseSearchFilters={this.state.cruiseSearchFilters}
                            updateBooking={this.updateBooking}
                          />
                        }
                      />
                      <Route
                        path="/book/:cruiseLine/:cruiseGroupId/:stepParam/:cruiseId"
                        element={
                          <BookingPage
                            bookingState={this.state.booking}
                            scrollToTop={this.scrollToTop}
                            cruiseSearchFilters={this.state.cruiseSearchFilters}
                            updateBooking={this.updateBooking}
                          />
                        }
                      />
                      <Route path="/favorite/:favoriteId" element={<FavoritePage />} />
                      <Route path="/offer/:offerSlug" element={<OfferPage updateBooking={this.updateBooking} />} />
                      <Route path="/checkout/:transactionId" element={<CheckoutPage />} />
                      <Route path="/about" element={<AboutPage />} />
                      <Route path="/faq" element={<FAQPage />} />
                      <Route path="/blog" element={<BlogPage />} />
                      <Route path="/contact" element={<ContactPage />} />
                      <Route path="/company-details" element={<AboutPage />} />
                      <Route path="/terms-and-conditions" element={<TermsAndConditionsPage />} />
                      <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
                      <Route path="/costa-toscana-dubai" element={<CostaToscanaDubaiLanding />} />
                      <Route path="/aldea-sea-experience" element={<CostaToscanaAldeaLanding />} />
                      <Route path="/not-found" element={<Page404 />} />
                      <Route path="*" element={<Navigate to="/" />} />
                    </Routes>
                  </Box>
                  <Footer scrollToTop={this.scrollToTop} />
                  {__CONFIG__.support.messengerLink && <MessengerFAB />}
                </Stack>
              </Box>
            </Router>
          </CheckoutApiProvider>
        </SearchApiProvider>
      </Box>
    );
  }
}

export default withTranslation()(
  withMediaQuery([
    [
      'isLayoutSmall',
      (theme: Theme) => theme.breakpoints.down('sm'),
      {
        defaultMatches: true
      }
    ],
    [
      'isLayoutMedium',
      (theme: Theme) => theme.breakpoints.down('md'),
      {
        defaultMatches: true
      }
    ]
  ])(App)
);
