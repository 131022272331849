import { Box, Grid, LinearProgress, Stack, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { SectionBox } from '../../components/section-box/section-box';
import { SubscriptionBox } from '../../components/subscription-box/subscription-box';

export const BlogPage: React.FC<{  }> = (props) => {
  const { t } = useTranslation('common');
  
  useEffect(() => {
    window.location.href = t('navigation.blog-link');
  });

  return <>
    <Box>
      <Stack spacing={8} >
        <SectionBox>
          <Stack alignItems="center" justifyContent="center" height="20vh">
            <Typography variant="h2" marginBottom="15px">{t('labels.redirecting')}</Typography>
            <Box style={{maxWidth: '600px', width: '100%'}}>
              <LinearProgress />
            </Box>
          </Stack>
        </SectionBox>
      </Stack>
    </Box>
  </>
}