import { Box, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useCallback } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { CruiseSearchFilters } from '../../models/CruiseSearchFilters';
import { CruiseDetails } from '../../services/search';
import { CruiseDetailsPanel } from '../cruise-details-panel/cruise-details-panel';
import { CruiseDetailsPanelV2Horizontal } from '../cruise-details-panel/cruise-details-panel-v2-horizontal';
import './cruise-search-result-list-item.scss';

import { useFeatureIsOn } from '@growthbook/growthbook-react';

export const CruiseSearchResultListItem: React.FC<{
  data: CruiseDetails;
  forceMediumLayout?: boolean;
  showPrice?: boolean;
  showButton?: boolean;
  detailPanel?: React.ReactElement;
  searchFilters?: CruiseSearchFilters;
}> = (props) => {
  const theme = useTheme();
  const isLayoutMedium = useMediaQuery(theme.breakpoints.down('md'), { noSsr: true });
  const isLayoutLarge = useMediaQuery(theme.breakpoints.up('md'), { noSsr: true });

  const isResultCardV2Enabled = useFeatureIsOn('result-card-v2');
  const preventDefault = useCallback((e) => e.preventDefault(), []);

  const ThumbnailImageContainer = () => {
    return (
      <>
        <Box
          className="thumbnail-image-container"
          minWidth={isLayoutMedium || props.forceMediumLayout === true ? '100%' : '45%'}
          maxHeight={isLayoutMedium || props.forceMediumLayout === true ? undefined : '450px'}
        >
          {props.data.imageUrls && (
            <Carousel
              infiniteLoop
              showIndicators={true}
              showThumbs={false}
              showStatus={false}
              showArrows={isLayoutLarge}
              transitionTime={400}
              preventMovementUntilSwipeScrollTolerance={true}
              onSwipeStart={(e) => e.target.addEventListener('touchmove', preventDefault)}
              onSwipeEnd={(e) => e.target.removeEventListener('touchmove', preventDefault)}
            >
              {props.data.imageUrls.map((i, index) => (
                <div
                  key={index}
                  id={`thumbnail-image-${props.data?.cruiseGroupId}-${index}`}
                  className="thumbnail-image"
                  style={{ backgroundImage: `url("${i}&w=960")` }}
                ></div>
              ))}
            </Carousel>
          )}
          <Box className="ship" p={1}>
            <img src={props.data.cruiseLineLogoUrl} alt={props.data.cruiseLine} />
            {props.data.shipName}
          </Box>
        </Box>
      </>
    );
  };

  return (
    <>
      <Box className="cruise-search-result-list-item-container">
        <Stack
          direction={props.forceMediumLayout === true ? 'column' : { xs: 'column', sm: 'column', md: 'row' }}
          width="calc(100% + 2px)"
          alignItems="stretch"
        >
          <ThumbnailImageContainer />
          <Box minWidth={isLayoutMedium || props.forceMediumLayout === true ? '100%' : '55%'} padding="15px">
            {props.detailPanel ||
              (isResultCardV2Enabled ? (
                <CruiseDetailsPanelV2Horizontal
                  data={props.data}
                  view="normal"
                  showPrice={props.showPrice}
                  showButton={props.showButton}
                />
              ) : (
                <CruiseDetailsPanel
                  data={props.data}
                  view="normal"
                  showPrice={props.showPrice}
                  showButton={props.showButton}
                  searchFilters={props.searchFilters}
                />
              ))}
          </Box>
        </Stack>
      </Box>
    </>
  );
};

CruiseSearchResultListItem.defaultProps = {
  showPrice: true,
  showButton: true
};
