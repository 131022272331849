import React, { useCallback, useMemo, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  TextField,
  Typography,
  CardMedia,
  CardHeader,
  debounce
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { SectionBox } from '../../components/section-box/section-box';
import { useTracking } from '../../hooks/useTracking';

import minimumAgeHu from './assets/minimumAgeHu.webm';
import buffetOpeningHoursHu from './assets/buffetOpeningHoursHu.webm';
import includedDrinksHu from './assets/includedDrinksHu.webm';
import drinkPackagesHu from './assets/drinkPackagesHu.webm';
import hungarianGuideHu from './assets/hungarianGuideHu.webm';
import foodAllergiesHu from './assets/foodAllergiesHu.webm';
import waterSlidesWinterHu from './assets/waterSlidesWinterHu.webm';
import windowCabinHu from './assets/windowCabinHu.webm';
import freeKidsCabinHu from './assets/freeKidsCabinHu.webm';
import portArrivalTimeHu from './assets/portArrivalTimeHu.webm';
import excludedActivitiesHu from './assets/excludedActivitiesHu.webm';
import tipsForCrewHu from './assets/tipsForCrewHu.webm';
import cabinTypeHu from './assets/cabinTypeHu.webm';
import freeWifiHu from './assets/freeWifiHu.webm';
import excursionReturnTimeHu from './assets/excursionReturnTimeHu.webm';
import luggageAllowanceHu from './assets/luggageAllowanceHu.webm';
import boardingTasksHu from './assets/boardingTasksHu.webm';
import toothpasteProvidedHu from './assets/toothpasteProvidedHu.webm';
import bringTowelsHu from './assets/bringTowelsHu.webm';
import childCardLimitHu from './assets/childCardLimitHu.webm';
import cashPaymentHu from './assets/cashPaymentHu.webm';
import paymentMethodsHu from './assets/paymentMethodsHu.webm';
import bringAlcoholOnboardHu from './assets/bringAlcoholOnboardHu.webm';
import portAddressHu from './assets/portAddressHu.webm';
import miniBarIncludedHu from './assets/miniBarIncludedHu.webm';
import cabinNoiseLevelHu from './assets/cabinNoiseLevelHu.webm';
import thematicEveningsHu from './assets/thematicEveningsHu.webm';

const ASSETS: Record<string, string> = {
  drinkPackagesHu,
  excursionReturnTimeHu,
  minimumAgeHu,
  windowCabinHu,
  luggageAllowanceHu,
  boardingTasksHu,
  toothpasteProvidedHu,
  bringTowelsHu,
  childCardLimitHu,
  cashPaymentHu,
  excludedActivitiesHu,
  freeKidsCabinHu,
  portArrivalTimeHu,
  cabinTypeHu,
  paymentMethodsHu,
  bringAlcoholOnboardHu,
  freeWifiHu,
  portAddressHu,
  includedDrinksHu,
  foodAllergiesHu,
  hungarianGuideHu,
  tipsForCrewHu,
  buffetOpeningHoursHu,
  miniBarIncludedHu,
  waterSlidesWinterHu,
  cabinNoiseLevelHu,
  thematicEveningsHu
};

const faqSectionKeys = [
  {
    title: 'beforeBooking',
    entries: [
      'includedMeals',
      'drinkPackage',
      'luggageArrival',
      'extraServicesDeadline',
      'emergencyContact',
      'postBookingExcursions',
      'shipAppAvailability',
      'departureArrivalTimes',
      'cabinLocation',
      'extraServicesApp',
      'ironing',
      'transferToPort',
      'buffetOpeningHours',
      'minimumAge',
      'freeKidsCabin',
      'waterSlidesWinter',
      'freeWifi',
      'portArrivalTime',
      'tipsForCrew',
      'excludedActivities',
      'includedDrinks',
      'drinkPackages',
      'hungarianGuide',
      'cabinType',
      'foodAllergies',
      'windowCabin'
    ]
  },
  {
    title: 'beforeDeparture',
    entries: [
      'excursionReturnTime',
      'cabinNoiseLevel',
      'thematicEvenings',
      'cashPayment',
      'bringAlcoholOnboard',
      'miniBarIncluded',
      'toothpasteProvided',
      'childCardLimit',
      'boardingTasks',
      'portAddress',
      'luggageAllowance',
      'bringTowels',
      'paymentMethods'
    ]
  },
  {
    title: 'ports',
    entries: [
      'marseilles',
      'fort_de_france',
      'le_havre',
      'miami',
      'new_york',
      'port_canaveral',
      'trieste',
      'genoa',
      'palermo',
      'civitavecchia',
      'venice',
      'bari',
      'naples',
      'livorno',
      'naha',
      'split',
      'kiel',
      'hamburg',
      'warnemunde',
      'barcelona',
      'tarragona',
      'santa_cruz_de_tenerife',
      'valencia',
      'alicante',
      'palma_de_mallorca',
      'rotterdam',
      'copenhagen',
      'abu_dhabi',
      'dubai',
      'doha',
      'funchal',
      'piraeus',
      'southampton'
    ]
  }
];

export const FAQPage: React.FC<{}> = (props) => {
  const { t } = useTranslation('common');
  const { trackEvent } = useTracking();
  const [searchTerm, setSearchTerm] = useState('');

  const logSearch = useCallback(
    debounce((term: string) => {
      if (term) {
        trackEvent('faq_search', { term });
      }
    }, 2000),
    []
  );

  const handleInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const term = e.target.value;
    setSearchTerm(term);
    logSearch(term);
  }, []);

  const faqSections = useMemo(() => {
    return faqSectionKeys.map((section) => ({
      name: section.title,
      title: t(`faq.sections.${section.title}`),
      entries: section.entries
        .map((entry) => ({
          name: entry,
          question: t(`faq.questions.${entry}.question`),
          answer: t(`faq.questions.${entry}.answer`, ''),
          video: t(`faq.questions.${entry}.video`, ''),
          tags: t(`faq.questions.${entry}.tags`, '')
            .split(',')
            .map((tag) => tag.trim()),
          emoji: t(`faq.questions.${entry}.emoji`)
        }))
        .map((entry) => ({
          ...entry,
          hasAnswer: !!entry.answer,
          hasVideo: !!(entry.video && ASSETS[entry.video])
        }))
        .filter((entry) => entry.hasAnswer || entry.hasVideo)
    }));
  }, [t]);

  const matchingEntries = faqSections
    .flatMap((section) => section.entries)
    .filter(
      (entry) =>
        !searchTerm ||
        entry.tags.some((tag) => tag.toLowerCase().includes(searchTerm.toLowerCase())) ||
        entry.question.toLowerCase().includes(searchTerm.toLowerCase()) ||
        entry.answer.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .map((entry) => entry.name);

  return (
    <Container maxWidth="lg">
      <Box sx={{ flexGrow: 1, py: 4 }}>
        <SectionBox>
          <Typography variant="h1" component="h1" gutterBottom align="center" sx={{ mb: 2 }}>
            {t('faq.title')}
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            placeholder={t('faq.searchPlaceholder')}
            value={searchTerm}
            onChange={handleInputChange}
            sx={{ mb: 4 }}
          />
        </SectionBox>

        {faqSections.map((section, sectionIndex) => (
          <SectionBox key={sectionIndex} boxParams={{ id: section.name }}>
            <Typography
              variant="h2"
              component="h2"
              gutterBottom
              align="center"
              sx={{ mb: 2, backgroundColor: '#f0f0f0', padding: '10px' }}
            >
              {section.title}
            </Typography>
            <Grid container spacing={4} mb={2}>
              {section.entries.map((entry, entryIndex) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  key={entryIndex}
                  sx={{ display: matchingEntries.includes(entry.name) ? 'auto' : 'none' }}
                >
                  <Card
                    elevation={6}
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      height: '100%'
                    }}
                    id={entry.name}
                  >
                    <CardHeader
                      avatar={
                        <Typography variant="h2" sx={{ fontSize: 20, mr: -0.5 }}>
                          {entry.emoji}
                        </Typography>
                      }
                      title={entry.question}
                      titleTypographyProps={{ variant: 'h5', component: 'div', fontSize: 16 }}
                      sx={{
                        mb: 0,
                        backgroundColor: '#2f323d',
                        color: 'white',
                        display: 'flex',
                        alignItems: 'center'
                      }}
                    />

                    {entry.hasAnswer && (
                      <CardContent sx={{ flexGrow: 1, backgroundColor: 'background.paper' }}>
                        <Typography variant="body1" color="text.secondary">
                          <span dangerouslySetInnerHTML={{ __html: entry.answer }} />
                        </Typography>
                      </CardContent>
                    )}

                    {entry.hasVideo && (
                      <CardMedia
                        component="video"
                        controls
                        playsInline
                        src={ASSETS[entry.video]}
                        onPlay={() => trackEvent('faq_video_play', { label: entry.name })}
                        sx={{
                          width: '100%',
                          height: '100%',
                          mt: 0,
                          aspectRatio: '9 / 16',
                          objectFit: 'cover'
                        }}
                      />
                    )}
                  </Card>
                </Grid>
              ))}
            </Grid>
          </SectionBox>
        ))}
      </Box>
    </Container>
  );
};
