import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardMedia,
  Container,
  Divider,
  Grid,
  Link,
  Rating,
  Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { SectionBox } from '../../components/section-box/section-box';
import akosImage from './images/akos.webp';
import akosAltImage from './images/akos-alt.webp';
import agnesImage from './images/agnes.webp';
import dorinaImage from './images/dorina.webp';
import erikaImage from './images/erika.webp';
import hugoImage from './images/hugo.webp';
import msc1Image from './images/msc-1.webp';
import msc2Image from './images/msc-2.webp';
import msc3Image from './images/msc-3.webp';
import petraImage from './images/petra.webp';
import TrustpilotLogo from './images/trustpilot-logo.svg';
import React, { useState } from 'react';

const imageMapping: Record<string, string> = {
  petraImage,
  akosImage,
  akosAltImage,
  dorinaImage,
  erikaImage,
  agnesImage,
  hugoImage
};

interface TeamMember {
  name: string;
  role: string;
  description: string;
  image: string;
  altImage?: string;
  row: number;
}

interface Review {
  name: string;
  rating: number;
  text: string;
  country: string;
}

const teamMembers: TeamMember[] = __CONFIG__.aboutPage.teamMembers.map((member: any) => ({
  ...member,
  image: imageMapping[member.image],
  altImage: member.altImage ? imageMapping[member.altImage] : undefined
}));

const reviews: Review[] = [
  {
    name: 'about.reviews.ferenc.name',
    rating: 5,
    text: 'about.reviews.ferenc.text',
    country: 'about.reviews.ferenc.country'
  },
  {
    name: 'about.reviews.zsofi.name',
    rating: 5,
    text: 'about.reviews.zsofi.text',
    country: 'about.reviews.zsofi.country'
  },
  {
    name: 'about.reviews.moni.name',
    rating: 5,
    text: 'about.reviews.moni.text',
    country: 'about.reviews.moni.country'
  }
];

const pressArticles = __CONFIG__.aboutPage.pressArticles;

const MemberCard = ({ member }: { member: TeamMember }) => {
  const { t } = useTranslation('common');
  const [isFlipped, setIsFlipped] = useState(false);

  return (
    <Card
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        transition: 'transform 0.3s ease-in-out',
        '&:hover': {
          transform: 'scale(1.05)'
        }
      }}
      elevation={6}
    >
      <CardMedia
        component="img"
        height="250"
        image={member.altImage && isFlipped ? member.altImage : member.image}
        alt={member.name}
        sx={{
          objectFit: 'cover',
          objectPosition: 'top'
        }}
        onClick={() => {
          setIsFlipped((f) => !f);
        }}
      />
      <CardContent sx={{ flexGrow: 1 }}>
        <Typography gutterBottom variant="h6" component="div">
          {t(member.name)}
        </Typography>
        <Typography variant="subtitle2" color="text.secondary" sx={{ mb: 1 }}>
          {t(member.role)}
        </Typography>
        <Typography variant="body2" color="text.secondary" fontSize={14}>
          {t(member.description)}
        </Typography>
      </CardContent>
    </Card>
  );
};

export const AboutPage: React.FC<{}> = (props) => {
  const { t } = useTranslation('common');

  const teamMembersByRow = teamMembers.reduce((acc, member) => {
    if (!acc[member.row]) {
      acc[member.row] = [];
    }
    acc[member.row].push(member);
    return acc;
  }, {} as Record<number, TeamMember[]>);

  return (
    <Container maxWidth="lg">
      <Box sx={{ flexGrow: 1, py: 4 }}>
        <SectionBox>
          <Typography variant="h3" component="h1" gutterBottom align="center" sx={{ mb: 2 }}>
            {t('about.title')}
          </Typography>
          <Typography variant="body1" align="center" sx={{ maxWidth: 800, mx: 'auto', lineHeight: 1.6 }}>
            {t('about.description')}
          </Typography>
        </SectionBox>

        <Box sx={{ my: 4 }}>
          <Typography variant="h3" component="h1" gutterBottom align="center" sx={{ mb: 2 }}>
            {t('about.teamTitle')}
          </Typography>
          {Object.entries(teamMembersByRow).map(([row, members]) => (
            <Grid container spacing={4} justifyContent="center" sx={{ mb: 4 }} key={row}>
              {members.map((member) => (
                <Grid item xs={12} sm={6} md={3} key={member.name}>
                  <MemberCard member={member} />
                </Grid>
              ))}
            </Grid>
          ))}
        </Box>

        <SectionBox>
          <Typography variant="h3" component="h1" gutterBottom align="center" sx={{ mb: 2 }}>
            {t('about.reviewsTitle')}
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 3 }}>
            <Link
              href="https://www.trustpilot.com/review/clickncruise.hu"
              target="_blank"
              rel="noopener noreferrer"
              sx={{ mb: 2, display: 'inline-block' }}
            >
              {t('about.readReviews')}
            </Link>
            <Link
              href="https://www.trustpilot.com/review/clickncruise.hu"
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                display: 'inline-block',
                '&:hover': {
                  opacity: 0.8
                }
              }}
            >
              <img src={TrustpilotLogo} alt={t('about.trustpilotAlt')} style={{ maxWidth: 200 }} />
            </Link>
          </Box>
          <Grid container spacing={3} justifyContent="center">
            {reviews.map((review, index) => (
              <Grid item xs={12} sm={4} key={index}>
                <Card
                  variant="outlined"
                  sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    p: 2
                  }}
                >
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                    <Typography variant="subtitle1" fontWeight="bold">
                      {t(review.name)}
                    </Typography>
                    <Rating value={review.rating} precision={0.5} readOnly />
                  </Box>
                  <Typography variant="body2" sx={{ flexGrow: 1 }} fontSize={14}>
                    {t(review.text)}
                  </Typography>
                  <Typography variant="caption" color="text.secondary" sx={{ mt: 1 }}>
                    {t(review.country)}
                  </Typography>
                </Card>
              </Grid>
            ))}
          </Grid>
        </SectionBox>

        {pressArticles.length > 0 && (
          <SectionBox>
            <Typography variant="h3" component="h1" gutterBottom align="center" sx={{ mb: 2, mt: 4 }}>
              {t('about.pressTitle')}
            </Typography>

            <Grid container spacing={3} justifyContent="center">
              {pressArticles.map((article, index) => (
                <Grid item xs={12} sm={4} key={index}>
                  <Card
                    component={Link}
                    href={article.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    variant="outlined"
                    sx={{
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      p: 2
                    }}
                  >
                    <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', mb: 2 }}>
                      <Avatar sx={{ bgcolor: 'primary.main', mr: 2, width: 40, height: 40 }}>
                        {article.title.charAt(0)}
                      </Avatar>
                      <Typography
                        variant="subtitle1"
                        fontWeight="bold"
                        sx={{
                          color: 'primary.main',
                          textDecoration: 'underline'
                        }}
                      >
                        {t(article.title)}
                      </Typography>
                    </Box>
                    <Typography variant="body2" sx={{ flexGrow: 1 }} fontSize={14}>
                      {t(article.description)}
                    </Typography>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </SectionBox>
        )}

        <SectionBox>
          <Typography variant="h3" component="h1" gutterBottom align="center" sx={{ mb: 2, mt: 4 }}>
            MSC Partner
          </Typography>
          <Grid container spacing={3} justifyContent="center">
            {[msc1Image, msc2Image, msc3Image].map((image, index) => (
              <Grid item xs={12} sm={4} key={index}>
                <Card
                  sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    p: 2,
                    overflow: 'hidden'
                  }}
                >
                  <CardMedia
                    component="img"
                    height="150"
                    image={image}
                    alt={`MSC Image ${index + 1}`}
                    sx={{
                      objectFit: 'cover',
                      objectPosition: 'center',
                      width: '100%',
                      height: 'auto',
                      aspectRatio: '1 / 1',
                      transition: 'transform 0.3s ease-in-out',
                      '&:hover': {
                        transform: 'scale(1.1)'
                      }
                    }}
                  />
                </Card>
              </Grid>
            ))}
          </Grid>
        </SectionBox>

        <SectionBox>
          <Typography variant="h3" component="h1" gutterBottom align="center" sx={{ mb: 2, mt: 4 }}>
            {t('about.companyTitle')}
          </Typography>
          <Box
            sx={{
              maxWidth: 600,
              mx: 'auto',
              textAlign: 'center',
              backgroundColor: 'rgba(0,0,0,0.03)',
              p: 3,
              borderRadius: 2
            }}
          >
            <Typography variant="h6" gutterBottom>
              {t('about.companyName')}
            </Typography>
            <Typography variant="body1" sx={{ mb: 1 }}>
              {t('about.registeredOffice')}
            </Typography>
            <Divider sx={{ my: 2 }} />
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={12} sm={6}>
                <Typography variant="body2" color="text.secondary">
                  {t('about.registrationNumber')}
                </Typography>
                <Typography variant="body1">{t('about.registrationNumberValue')}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body2" color="text.secondary">
                  {t('about.taxNumber')}
                </Typography>
                <Typography variant="body1">{t('about.taxNumberValue')}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body2" color="text.secondary">
                  {t('about.tourismLicenseNumber')}
                </Typography>
                <Typography variant="body1">{t('about.tourismLicenseNumberValue')}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body2" color="text.secondary">
                  {t('about.email')}
                </Typography>
                <Typography variant="body1">
                  <Link href={`mailto:${__CONFIG__.support.email}`}>{__CONFIG__.support.email}</Link>
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </SectionBox>
      </Box>
    </Container>
  );
};
