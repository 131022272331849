import { PriceType } from '../components/price/price';
import { CreateBookingRequest, GuestDetails, UpdateBookingRequest } from '../services/checkout';
import { CruiseCabin, CruiseDepartureDate, CruiseDetails, CruiseService, ItineraryStep } from '../services/search';
import { BrowserStorage, STORAGE_KEY_BOOKING } from './BrowserStorage';
import { GuestsFilter } from './CruiseSearchFilters';

const MSC_DEPOSIT_PER_PERSON = 100;
const NON_MSC_DEPOSIT_MULTIPLIER = 0.25;

export type GuestType = 'adult' | 'child';

export const CheckoutStatus = {
  INITIATED: 'initiated',
  DATE_SELECTED: 'date_selected',
  CABIN_SELECTED: 'cabin_selected',
  GUESTS_PROVIDED: 'guests_provided'
};

export class BookingStep {
  index: number;
  title: string;
  path: string;

  constructor(index: number, path: string) {
    this.index = index;
    this.title = `booking.${path}.title`;
    this.path = path;
  }
}

export const BookingSteps = [
  new BookingStep(0, 'departure'),
  new BookingStep(1, 'cabintype'),
  new BookingStep(2, 'services'),
  new BookingStep(3, 'guests'),
  new BookingStep(4, 'confirmation')
];

export class IBookingGuestState {
  firstName?: string = '';
  middleName?: string = '';
  lastName?: string = '';
  gender?: string = '';
  nationality?: string = '';
  dateOfBirth?: Date;
  dateOfBirthDate?: Date;
  email?: string = '';
  phone?: string = '';
  type: GuestType = 'adult';
  title?: string = '';
}

export class BookingGuestState implements IBookingGuestState {
  firstName?: string = '';
  middleName?: string = '';
  lastName?: string = '';
  gender?: string = '';
  nationality?: string = '';
  dateOfBirth?: Date;
  dateOfBirthDate?: Date;
  email?: string = '';
  phone?: string = '';
  type: GuestType = 'adult';
  title?: string = '';

  constructor(source?: IBookingGuestState) {
    if (source) {
      this.firstName = source.firstName;
      this.middleName = source.middleName;
      this.lastName = source.lastName;
      this.gender = source.gender;
      this.nationality = source.nationality;
      this.dateOfBirth = source.dateOfBirth;
      this.dateOfBirthDate = source.dateOfBirthDate;
      this.email = source.email;
      this.phone = source.phone;
      this.type = source.type;
      this.title = source.title;
    }
  }

  toRequest = (): GuestDetails => {
    return {
      firstName: this.firstName,
      middleName: this.middleName,
      lastName: this.lastName,
      gender: this.gender,
      nationality: this.nationality,
      dateOfBirth: this.dateOfBirthDate,
      email: this.email,
      phone: this.phone,
      type: this.type,
      title: this.title
    };
  };
}

export class BookingInvoiceState {
  firstName: string = '';
  lastName: string = '';
  country: string = __CONFIG__.booking.form.defaultInvoiceCountry;
  city: string = '';
  address: string = '';
  zipCode: string = '';
  email: string = '';
}

export class BookingLegalState {
  allChecked: boolean = false;
  termsAndConditions: boolean = false;
  cruiselineTerms: boolean = false;
  privacyPolicy: boolean = false;
  newsLetter: boolean = false;
}

export class BookingGuestsState {
  guestList: BookingGuestState[] = [];
  invoice: BookingInvoiceState = new BookingInvoiceState();
  legalChecks: BookingLegalState = new BookingLegalState();
  stripeCustomerId: string = '';
  notes: string = '';

  constructor(source?: BookingGuestsState) {
    if (source) {
      this.invoice = source.invoice;
      this.legalChecks = source.legalChecks;
      this.stripeCustomerId = source.stripeCustomerId;
      this.notes = source.notes;

      this.guestList = source.guestList.map((g) => new BookingGuestState(g));
    }
  }

  getGuestListByType = (type: GuestType): Array<BookingGuestState> => {
    return this.guestList.filter((g) => g.type === type);
  };

  removeLasGuesttOfType = (type: GuestType): void => {
    let lastIndex = -1;
    this.guestList.forEach((g, i) => {
      if (g.type === type) lastIndex = i;
    });
    this.guestList.splice(lastIndex, 1);
  };
}

export class BookingCruiseDetails implements CruiseDetails {
  imageUrls?: Array<string>;
  cruiseLineLogoUrl: string;
  cruiseLine: string;
  shipName: string;
  shipCode: string;
  destinationRegion: string;
  portsOfCall: Array<string>;
  pricePerPerson: number;
  priceLocalCcy: number;
  cruiseGroupId: string;
  cruiseLength: number;
  departureDates?: Array<CruiseDepartureDate>;
  cabins?: Array<BookingCruiseCabin>;
  services?: Array<CruiseService>;
  seaDays: number;
  itinerary: Array<ItineraryStep>;

  constructor(cd: CruiseDetails | any) {
    this.imageUrls = cd.imageUrls;
    this.cruiseLineLogoUrl = cd.cruiseLineLogoUrl;
    this.cruiseLine = cd.cruiseLine;
    this.shipCode = cd.shipCode;
    this.shipName = cd.shipName;
    this.destinationRegion = cd.destinationRegion;
    this.portsOfCall = cd.portsOfCall;
    this.pricePerPerson = cd.pricePerPerson;
    this.priceLocalCcy = cd.priceLocalCcy;
    this.cruiseGroupId = cd.cruiseGroupId;
    this.cruiseLength = cd.cruiseLength;
    this.seaDays = cd.seaDays;
    this.itinerary = cd.itinerary || [];
    this.cabins = cd.cabins || [];

    if (this.itinerary && this.itinerary.length > 0) {
      this.itinerary = this.itinerary.map((e) => {
        e.arrivalTime = e.arrivalTime || '';
        e.departureTime = e.departureTime || '';
        return e;
      });
    }

    if (cd.departureDates) {
      this.departureDates = cd.departureDates.map((d: CruiseDepartureDate) => {
        d.fromDate = new Date(d.fromDate);
        d.toDate = new Date(d.toDate);
        return d;
      });
      this.departureDates?.sort((a, b) => a.fromDate.getTime() - b.fromDate.getTime());
    }
  }

  isValid() {
    return this.cruiseGroupId && this.portsOfCall && this.portsOfCall.length > 0;
  }
}

export class BookingCruiseCabin implements CruiseCabin {
  id: string;
  imageUrl: string;
  category: string;
  categoryGroup: string;
  description: string;
  pricePerPerson: number;
  pricePerPersonLocalCcy: number;
  cabinCode: string;
  cruiseLine: string;
  refreshTime: Date;
  availableCount: number;
  cabinTags: string[];

  constructor(cc: CruiseCabin, destinationRegion: string | undefined, firstPortOfCall: string | undefined) {
    this.id = cc.id;
    this.imageUrl = cc.imageUrl;
    this.category = cc.category;
    this.categoryGroup = cc.categoryGroup;
    this.description = cc.description;
    this.pricePerPerson = cc.pricePerPerson;
    this.pricePerPersonLocalCcy = cc.pricePerPersonLocalCcy;
    this.refreshTime = cc.refreshTime;
    this.availableCount = cc.availableCount;
    this.cabinTags = cc.cabinTags;

    let a = cc.id.split('_');
    this.cruiseLine = a[0];
    this.cabinCode = `${a[2]}_${a[3]}`;
  }
}

export class BookingState {
  bookingId?: string;
  activeStep: BookingStep = BookingSteps[0];
  completedSteps: number[] = [];
  bookingCompleted: boolean = false;
  numberOfGuests: GuestsFilter = new GuestsFilter();
  departureDate: CruiseDepartureDate | null = null;
  cabinType: BookingCruiseCabin | null = null;
  services: Array<CruiseService> = [];
  guestsState: BookingGuestsState = new BookingGuestsState();
  bookingDetails: BookingCruiseDetails | null = null;
  pricePerPerson: number = 0;
  pricePerPersonLocalCcy: number = 0;
  refreshTime: Date = new Date();
  availableCount: number = 1;
  source: string = '';
  discounts: { [key: string]: number } = {};
  cabinTags: string[] = [];

  constructor(source: string | BookingState | null) {
    if (source && typeof source === 'string') {
      this.init(JSON.parse(source));
    } else if (source) {
      this.init(source as BookingState);
    }
  }
  private init(o: BookingState): void {
    if (o) {
      this.bookingId = o.bookingId;
      this.bookingDetails = o.bookingDetails ? new BookingCruiseDetails(o.bookingDetails) : null;
      if (!this.bookingDetails?.isValid()) {
        this.bookingDetails = null;
      }
      this.activeStep = o.activeStep || BookingSteps[0];
      this.completedSteps = o.completedSteps || [];
      this.bookingCompleted = o.bookingCompleted || false;
      this.numberOfGuests = new GuestsFilter(o.numberOfGuests);
      this.departureDate = null;
      if (o.departureDate) {
        this.departureDate = o.departureDate;
        this.departureDate.fromDate = new Date(this.departureDate.fromDate);
        this.departureDate.toDate = new Date(this.departureDate.toDate);
      }
      const destinationRegion = o.bookingDetails?.destinationRegion;
      const firstPortOfCall = o.bookingDetails?.portsOfCall?.[0];

      this.cabinType = o.cabinType ? new BookingCruiseCabin(o.cabinType, destinationRegion, firstPortOfCall) : null;
      this.services = o.services || [];
      this.guestsState = new BookingGuestsState(o.guestsState);
      this.pricePerPerson = o.pricePerPerson;
      this.pricePerPersonLocalCcy = o.pricePerPersonLocalCcy;
      this.discounts = o.discounts || {};
    }
  }

  store = () => {
    BrowserStorage.getInstance().setItem(STORAGE_KEY_BOOKING, JSON.stringify(this));
  };

  reset = () => {
    this.bookingId = undefined;
    this.activeStep = BookingSteps[0];
    this.completedSteps = [];
    this.bookingCompleted = false;
    this.departureDate = null;
    this.cabinType = null;
    this.guestsState = new BookingGuestsState();
    this.services = [];
    this.discounts = {};
  };

  calculateServicesPrice = (priceAttr: 'eur' | 'local') => {
    let result = 0;
    if (this.services && this.services.length > 0) {
      result = this.services
        .map((s) => {
          let p = s.price;
          if (priceAttr === 'local') {
            p = s.priceLocalCcy;
          }
          return s.perPerson ? p : p / this.guestsState.guestList.length;
        })
        .reduce((partialSum, a) => partialSum + a);
    }
    return result;
  };

  calculatePricePerPerson = () => {
    return this.pricePerPerson + this.calculateServicesPrice('eur');
  };

  calculateDiscountAmount = () => {
    return Object.values(this.discounts).reduce((acc, curr) => acc + curr, 0);
  };

  calculateTotalPrice = (withDiscount: boolean) => {
    const withoutDiscount = this.calculatePricePerPerson() * this.numberOfGuests.getAllCount();
    return withDiscount ? withoutDiscount - this.calculateDiscountAmount() : withoutDiscount;
  };

  calculateLocalCurrencyRate = () => {
    return this.pricePerPersonLocalCcy / this.pricePerPerson;
  };

  getTotalPrice = (withDiscount: boolean) => {
    return new PriceType(
      Math.round(this.calculateTotalPrice(withDiscount) / this.numberOfGuests.getAllCount()),
      __CONFIG__.currency.default,
      this.numberOfGuests.getAllCount()
    );
  };

  getTotalPriceLocalCcy = (withDiscount: boolean) => {
    return new PriceType(
      Math.round(
        (this.calculateTotalPrice(withDiscount) / this.numberOfGuests.getAllCount()) * this.calculateLocalCurrencyRate()
      ),
      __CONFIG__.currency.localCurrency,
      this.numberOfGuests.getAllCount(),
      'estimate'
    );
  };

  getDepositPaymentDate = () => {
    const d = new Date();
    d.setDate(d.getDate() + __CONFIG__.booking.deposit.depositPaymentDays);
    return d;
  };

  getLastPaymentDate = () => {
    if (!this.departureDate) {
      return new Date();
    }

    const d = new Date(this.departureDate.fromDate);
    d.setMonth(d.getMonth() - __CONFIG__.booking.deposit.lastPaymentBeforeDepartureMonths);
    return d;
  };

  isMSCPromo = () => {
    return ['MSC96H', '96DRINK', 'LASTMIN', 'LMDRINK', 'PRFLASH', 'FLASHDRI'].some((s) =>
      this.cabinType?.id.includes(s)
    );
  };

  shouldPayDeposit = () => {
    const lastDayForDeposit = new Date();
    lastDayForDeposit.setDate(lastDayForDeposit.getDate() + __CONFIG__.booking.deposit.shouldPayDepositLimitDays);
    const onlyMscBooking = !__CONFIG__.booking.deposit.onlyMSC || this.bookingDetails?.cruiseLine === 'MSC';
    return (
      onlyMscBooking && !this.isMSCPromo() && this.departureDate && this.departureDate.fromDate > lastDayForDeposit
    );
  };

  calculateDeposit = () => {
    const total = Math.round(this.calculateTotalPrice(false));
    if (__CONFIG__.booking.deposit.onlyMSC) {
      return Math.min(this.numberOfGuests.adult * MSC_DEPOSIT_PER_PERSON, total);
    } else {
      return Math.round(total * NON_MSC_DEPOSIT_MULTIPLIER);
    }
  };

  getDepositPrice = () => {
    return new PriceType(this.calculateDeposit(), __CONFIG__.currency.default, -1);
  };

  getRemainingPrice = (withDiscount: boolean) => {
    return new PriceType(
      (Math.round(this.calculateTotalPrice(withDiscount)) - this.calculateDeposit()) /
        this.numberOfGuests.getAllCount(),
      __CONFIG__.currency.default,
      this.numberOfGuests.getAllCount()
    );
  };

  getDepositPriceLocalCcy = () => {
    return new PriceType(
      Math.round(this.calculateDeposit() * this.calculateLocalCurrencyRate()),
      __CONFIG__.currency.localCurrency,
      -1,
      'estimate'
    );
  };

  getRemainingPriceLocalCcy = (withDiscount: boolean) => {
    return new PriceType(
      Math.round(
        ((this.calculateTotalPrice(withDiscount) - this.calculateDeposit()) / this.numberOfGuests.getAllCount()) *
          this.calculateLocalCurrencyRate()
      ),
      __CONFIG__.currency.localCurrency,
      this.numberOfGuests.getAllCount(),
      'estimate'
    );
  };

  toCreateBookingPayload = (): CreateBookingRequest => {
    const bd = this.bookingDetails;

    return {
      status: CheckoutStatus.INITIATED,
      cruiseLine: bd?.cruiseLine,
      shipCode: bd?.shipCode,
      destinationRegion: bd?.destinationRegion,
      cruiseGroupId: bd?.cruiseGroupId
    };
  };

  toUpdateBookingPayload = (status: string): UpdateBookingRequest => {
    if (!this.bookingId) {
      throw new Error('Booking Id is not set');
    }

    const bd = this.bookingDetails;

    return {
      id: this.bookingId,
      status: status,
      cruiseLine: bd?.cruiseLine,
      shipCode: bd?.shipCode,
      destinationRegion: bd?.destinationRegion,
      cruiseGroupId: bd?.cruiseGroupId,

      departureId: this.departureDate?.id,
      departureFromDate: this.departureDate?.fromDate,
      departureToDate: this.departureDate?.toDate,
      departurePricePerPerson: this.departureDate?.pricePerPerson,
      departurePriceLocalCcy: this.departureDate?.pricePerPersonLocalCcy,

      cabinId: this.cabinType?.id,
      cabinCategory: this.cabinType?.category,
      cabinPricePerPerson: this.cabinType?.pricePerPerson,
      cabinPriceLocalCcy: this.cabinType?.pricePerPersonLocalCcy,

      invoiceFirstName: this.guestsState.invoice.firstName,
      invoiceLastName: this.guestsState.invoice.lastName,
      invoiceCountry: this.guestsState.invoice.country,
      invoiceCity: this.guestsState.invoice.city,
      invoiceAddress: this.guestsState.invoice.address,
      invoiceZipcode: this.guestsState.invoice.zipCode,
      invoiceEmail: this.guestsState.invoice.email,

      pricePerPerson: this.pricePerPerson,
      priceLocalCcy: this.pricePerPersonLocalCcy,
      newsletter: this.guestsState.legalChecks.newsLetter,

      stripeCustomerId: this.guestsState.stripeCustomerId,
      notes: this.guestsState.notes,

      services: this.services.map((s) => `${s.name}: ${s.price} EUR`).join(','),

      guests: this.guestsState.guestList.map((g) => g.toRequest()),

      totalPriceShown: this.getTotalPrice(true).amount,
      departurePort: bd?.portsOfCall?.[0],
      source: this.source,
      discounts: this.discounts
    };
  };
}
