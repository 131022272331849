import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import useMediaQuery from '@mui/material/useMediaQuery';
import useTheme from '@mui/material/styles/useTheme';
import Stack from '@mui/material/Stack';
import React, { ReactElement, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import './navigation-drawer.scss';

import ArrowBackIos from '@mui/icons-material/ArrowBackIos';
import MenuIcon from '@mui/icons-material/Menu';
import { useTranslation } from 'react-i18next';
import { LanguageSwitcher } from '../language-switcher/language-switcher';
import { Logo, LogoIconOnly } from '../logo/logo';
import AuthMenuItem from '../auth-menu-item/auth-menu-item';
import { useFeatureIsOn } from '@growthbook/growthbook-react';

export const NavigationDrawer: React.FC<{ scrollToTop: () => void; extraIcon?: ReactElement }> = (props) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { t } = useTranslation('common');
  const [openDrawer, setOpenDrawer] = useState(false);
  const theme = useTheme();
  const isLayoutSmall = useMediaQuery(theme.breakpoints.down('md'), { noSsr: true });
  const isLoginEnabled = useFeatureIsOn('login');

  const goBack = () => {
    navigate(-1);
  };

  return (
    <>
      <Drawer className="navigation-drawer" open={openDrawer} onClose={() => setOpenDrawer(false)} anchor="right">
        <List>
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <Link className={'link'} to="/">
                <LogoIconOnly width={30} />
              </Link>
            </ListItemText>
          </ListItem>
          <Divider />
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <Link className={'link'} to={isLayoutSmall ? '/filter' : '/search'}>
                {t('navigation.search')}
              </Link>
            </ListItemText>
          </ListItem>

          <Divider />

          {__CONFIG__.pages.faq.faqEnabled && (
            <ListItem onClick={() => setOpenDrawer(false)}>
              <ListItemText>
                <Link className={'link'} to="/faq">
                  {t('navigation.faq')}
                </Link>
              </ListItemText>
            </ListItem>
          )}

          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <Link className={'link'} to="/about">
                {t('navigation.about-us')}
              </Link>
            </ListItemText>
          </ListItem>

          <Divider />

          {__CONFIG__.pages.blog.blogEnabled && (
            <ListItem onClick={() => setOpenDrawer(false)}>
              <ListItemText>
                <a className={'link'} href={t('navigation.blog-link')}>
                  {t('navigation.blog')}
                </a>
              </ListItemText>
            </ListItem>
          )}

          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              {__CONFIG__.pages.contact.pageEnabled && (
                <Link className={'link'} to="/contact">
                  {t('navigation.contact-us')}
                </Link>
              )}
              {!__CONFIG__.pages.contact.pageEnabled && (
                <a className={'link'} href={__CONFIG__.support.messengerLink} target="_blank">
                  {t('navigation.contact-us')}
                </a>
              )}
            </ListItemText>
          </ListItem>

          {__CONFIG__.i18n.languages.length > 1 && (
            <ListItem onClick={() => setOpenDrawer(false)}>
              <ListItemText>
                <LanguageSwitcher />
              </ListItemText>
            </ListItem>
          )}
        </List>
      </Drawer>
      <Grid className="navigation-drawer-top-bar" container justifyContent="space-between" alignItems="center">
        <Grid item xs={3}>
          <IconButton
            onClick={goBack}
            style={{ visibility: pathname !== '/' && window.history.length > 2 ? 'visible' : 'hidden' }}
          >
            <ArrowBackIos />
          </IconButton>
        </Grid>
        <Grid item alignItems="center" display="flex">
          <Logo color="dark" width={140} />
        </Grid>
        <Grid item xs={3}>
          <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={0}>
            {props.extraIcon && !isLoginEnabled && <div style={{ marginRight: 8 }}>{props.extraIcon}</div>}
            {isLoginEnabled && <AuthMenuItem />}
            <IconButton onClick={() => setOpenDrawer(!openDrawer)}>
              <MenuIcon />
            </IconButton>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};
