import { Grid, Stack, Typography, Link } from '@mui/material';
import { Box } from '@mui/system';
import { Link as RouterLink } from 'react-router-dom';
import './footer.scss';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTranslation } from 'react-i18next';

export const Footer: React.FC<{ scrollToTop: () => void }> = (props) => {
  const theme = useTheme();
  const isLayoutSmall = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });
  const isLayoutMedium = useMediaQuery(theme.breakpoints.down('md'), { noSsr: true });

  const { t } = useTranslation('common');

  const FooterLink = (args: any) => {
    if (args.href === '') {
      return <></>;
    }
    return <Link {...args} fontSize={isLayoutSmall ? '12px' : ''} onClick={props.scrollToTop} />;
  };
  return (
    <>
      <Box
        className="footer"
        style={{ padding: isLayoutSmall ? '20px' : '20px 110px', marginTop: '0', background: '#E5E5E5' }}
      >
        <Grid justifyContent={isLayoutMedium ? 'flex-start' : 'space-around'} container spacing={4}>
          <Grid item xs={6} sm={6} md={3}>
            <Stack spacing={2}>
              <Typography variant="h3">{t('labels.footer.community')}</Typography>
              {__CONFIG__.pages.blog.blogEnabled && (
                <FooterLink href={t('navigation.blog-link')} target="_blank">
                  Blog
                </FooterLink>
              )}
              <FooterLink href={t('labels.footer.link.facebook')} target="_blank">
                Facebook
              </FooterLink>
              <FooterLink href={t('labels.footer.link.instagram')} target="_blank">
                Instagram
              </FooterLink>
              <FooterLink href={t('labels.footer.link.linkedin')} target="_blank">
                Linkedin
              </FooterLink>
              <FooterLink href={t('labels.footer.link.tiktok')} target="_blank">
                TikTok
              </FooterLink>
            </Stack>
          </Grid>
          <Grid item xs={6} sm={6} md={3}>
            <Stack spacing={2}>
              <Typography variant="h3">{t('labels.footer.security-and-data')}</Typography>
              <FooterLink to="/about" component={RouterLink}>
                {t('labels.footer.about')}
              </FooterLink>
              <FooterLink to="/terms-and-conditions" component={RouterLink}>
                {t('terms-conditions.title')}
              </FooterLink>
              <FooterLink to="/privacy-policy" component={RouterLink}>
                {t('privacy-policy.title')}
              </FooterLink>
            </Stack>
          </Grid>
        </Grid>
        <Grid
          container
          className="footer-copyright"
          sx={{ paddingBottom: isLayoutSmall ? '50px' : '' }}
          justifyContent="space-between"
        >
          <Grid item xs={12} sm={10}>
            <Typography variant="body1">
              <b>{t('footer.title')}</b>
              <br />
              {t('footer.p1')}
              <br />
              {t('footer.p2')}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={2} textAlign={isLayoutSmall ? 'center' : 'right'}>
            {__CONFIG__.pages.footer.logoEnabled && <img src="/footer/abta.webp" alt="abta" height="75" />}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
