import { Box, Link, Stack } from '@mui/material';
import { SectionBox } from '../components/section-box/section-box';
import { SectionTitle } from '../components/section-title/section-title';
import { useTranslation } from 'react-i18next';

export const TermsAndConditionsPage: React.FC<{}> = (props) => {
  const { t } = useTranslation('common');

  return (
    <>
      <Box>
        <Stack spacing={8}>
          <Box padding={{ xs: '0 20px', md: '0 100px' }}>
            <SectionTitle title={t('terms-conditions.title')} />
            <SectionBox boxParams={{ style: { marginTop: '40px', textAlign: 'center' } }}>
              <Link href={t('terms-conditions.link')} target="_blank">
                {t('terms-conditions.download')}
              </Link>
              <p></p>
            </SectionBox>
          </Box>
        </Stack>
      </Box>
    </>
  );
};
