import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DatePickerInputSingle } from '../../components/input-datepicker/input-datepicker';
import { InputSelect, InputText } from '../../components/input-text/input-text';
import { BookingGuestState, BookingState } from '../../models/BookingState';
import './booking-guest-details-form.scss';

import { useFormContext } from 'react-hook-form';

interface BookingGuestDetailsFormProps {
  guestIndex: number;
  guestState: BookingGuestState;
  bookingState: BookingState;
  updateGuestState: (g: BookingGuestState) => void;
}

export const BookingGuestDetailsForm: React.FC<BookingGuestDetailsFormProps> = (props) => {
  // Translation
  const { t } = useTranslation('common');

  // Form
  const { formState } = useFormContext();

  // Methods

  const getFieldName = (fieldId: string) => {
    return fieldId.split('-')[0];
  };

  const updateGuestField = (fieldId: string, value: any) => {
    const fieldName = getFieldName(fieldId);

    (props.guestState as any)[fieldName] = value;
    props.updateGuestState(props.guestState);
  };

  const hasError = (): boolean => {
    for (let e in formState.errors) {
      if (e.endsWith(`guest-${props.guestIndex}`)) {
        return true;
      }
    }
    return false;
  };

  // Change handlers
  const handleGuestFieldChange = (event: any) => {
    updateGuestField(event.target.id || event.target.name, event.target.value);
  };

  const handleDateChange = (id: string, date: Date) => {
    updateGuestField(`dateOfBirth-input-guest-${props.guestIndex}`, date.toLocaleDateString());
    updateGuestField(
      `dateOfBirthDate-input-guest-${props.guestIndex}`,
      new Date(date.getTime() + Math.abs(date.getTimezoneOffset()) * 60 * 1000)
    );
  };

  const nameOrder = __CONFIG__.booking.form.reverseNameOrder ? ['lastName', 'firstName'] : ['firstName', 'lastName'];

  return (
    <>
      <Grid className="booking-guest-details-form" container columnSpacing={3} rowSpacing={1} marginBottom={3}>
        <Grid item xs={12}>
          <Typography
            className={`form-group-header ${hasError() ? 'error' : ''}`}
            variant="h3"
            textAlign="left"
            marginBottom="0px"
          >
            {t('booking.guests.form.guest-data-form-header', {
              number: props.guestIndex + 1,
              guestType: t(`labels.${props.guestState.type}`)
            })}
          </Typography>
        </Grid>

        {__CONFIG__.booking.form.titleEnabled && (
          <Grid item xs={12} md={6}>
            <InputSelect
              id={`title-input-guest-${props.guestIndex}`}
              label="booking.guests.form.title.label"
              placeholder="booking.guests.form.country.placeholder"
              onInputBlur={handleGuestFieldChange}
              validationRules={{
                required: t('form.required-helper-text'),
                pattern: {
                  value: /([a-zA-Z]+)/,
                  message: t('form.required-helper-text')
                }
              }}
              defaultValue={(props.guestState as any)['title']}
              options={[
                { label: 'Mr', value: 'Mr' },
                { label: 'Mrs', value: 'Mrs' },
                { label: 'Miss', value: 'Miss' },
                { label: 'Ms', value: 'Ms' },
                { label: 'Mx', value: 'Mx' }
              ]}
            />
          </Grid>
        )}

        <Grid item xs={12} md={6}>
          <InputText
            id={`${nameOrder[0]}-input-guest-${props.guestIndex}`}
            label={`booking.guests.form.${nameOrder[0]}.label`}
            placeholder={`booking.guests.form.${nameOrder[0]}.placeholder`}
            onInputBlur={handleGuestFieldChange}
            validationRules={{
              required: t('form.required-helper-text'),
              pattern: {
                value: /([a-zA-Z]+)/,
                message: t('form.required-helper-text')
              }
            }}
            defaultValue={(props.guestState as any)[nameOrder[0]]}
          />
        </Grid>

        {__CONFIG__.booking.form.middleNameEnabled && (
          <Grid item xs={12} md={6}>
            <InputText
              id={`middleName-input-guest-${props.guestIndex}`}
              label={`booking.guests.form.middleName.label`}
              placeholder={`booking.guests.form.middleName.placeholder`}
              onInputBlur={handleGuestFieldChange}
              validationRules={{
                pattern: {
                  value: /([a-zA-Z]+)/,
                  message: t('form.required-helper-text')
                }
              }}
              defaultValue={(props.guestState as any)['middleName']}
            />
          </Grid>
        )}

        <Grid item xs={12} md={6}>
          <InputText
            id={`${nameOrder[1]}-input-guest-${props.guestIndex}`}
            label={`booking.guests.form.${nameOrder[1]}.label`}
            placeholder={`booking.guests.form.${nameOrder[1]}.placeholder`}
            onInputBlur={handleGuestFieldChange}
            validationRules={{
              required: t('form.required-helper-text'),
              pattern: {
                value: /([a-zA-Z]+)/,
                message: t('form.required-helper-text')
              }
            }}
            defaultValue={(props.guestState as any)[nameOrder[1]]}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <DatePickerInputSingle
            id={`dateOfBirth-input-guest-${props.guestIndex}`}
            label={t('booking.guests.form.date-of-birth.label') + '*'}
            format={t('booking.guests.form.date-of-birth.placeholder')}
            onChanged={handleDateChange}
            validationRules={{
              required: `${t('form.required-helper-text')}`
            }}
            value={props.guestState.dateOfBirth}
          />
        </Grid>

        {__CONFIG__.booking.form.nationalityEnabled && (
          <Grid item xs={12} md={6}>
            <InputText
              id={`nationality-input-guest-${props.guestIndex}`}
              label={`booking.guests.form.nationality.label`}
              placeholder={`booking.guests.form.nationality.placeholder`}
              onInputBlur={handleGuestFieldChange}
              validationRules={{
                required: `${t('form.required-helper-text')}`,
                pattern: {
                  value: /([a-zA-Z]+)/,
                  message: t('form.required-helper-text')
                }
              }}
              defaultValue={(props.guestState as any)['nationaility']}
            />
          </Grid>
        )}

        {props.guestIndex === 0 && (
          <Grid item xs={12} md={6}>
            <InputText
              id={`email-input-guest-${props.guestIndex}`}
              label={`booking.guests.form.email.label`}
              placeholder={`booking.guests.form.email.placeholder`}
              onInputBlur={handleGuestFieldChange}
              validationRules={{
                required: `${t('form.required-helper-text')}`,
                pattern: {
                  value: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,4})+$/,
                  message: t('form.invalid-email-text')
                }
              }}
              defaultValue={props.guestState['email']}
            />
          </Grid>
        )}

        {props.guestIndex === 0 && (
          <Grid item xs={12} md={6}>
            <InputText
              id={`phone-input-guest-${props.guestIndex}`}
              label={`booking.guests.form.phone-number.label`}
              placeholder={`booking.guests.form.phone-number.placeholder`}
              onInputBlur={handleGuestFieldChange}
              validationRules={{
                required: `${t('form.required-helper-text')}`,
                pattern: {
                  value: /^(\+{0,1})([0-9]*)$/,
                  message: t('form.invalid-email-text')
                }
              }}
              defaultValue={props.guestState.phone}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};
