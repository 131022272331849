import GroupsIcon from '@mui/icons-material/Groups';
import AppBar from '@mui/material/AppBar';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';
import useMediaQuery from '@mui/material/useMediaQuery';
import useTheme from '@mui/material/styles/useTheme';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useTracking } from '../../hooks/useTracking';
import { LanguageSwitcher } from '../language-switcher/language-switcher';
import { Logo } from '../logo/logo';
import { NavigationDrawer } from '../navigation-drawer/navigation-drawer';
import './navigation-bar.scss';
import AuthMenuItem from '../auth-menu-item/auth-menu-item';
import { useFeatureIsOn } from '@growthbook/growthbook-react';

const FacebookGroupBadge: React.FC<{ color: string }> = (props) => {
  const url = __CONFIG__.pages.facebookGroup;

  const [badgeClicked, setBadgeClicked] = useState<boolean>(localStorage.getItem('facebook_badge_clicked') === 'true');
  const { trackEvent } = useTracking();

  const handleClick = () => {
    trackEvent('facebook_badge_clicked');
    localStorage.setItem('facebook_badge_clicked', 'true');
    setBadgeClicked(true);
  };

  return (
    <a onClick={handleClick} href={url} target="_blank">
      <Badge badgeContent={1} color="error" invisible={badgeClicked} style={{ color: props.color }}>
        <GroupsIcon />
      </Badge>
    </a>
  );
};

export const NavigationBar: React.FC<{ scrollToTop: () => void; desktopHomeHeader: boolean }> = (props) => {
  const { t } = useTranslation('common');
  const theme = useTheme();
  const isLayoutSmall = useMediaQuery(theme.breakpoints.down('md'), { noSsr: true });
  const showFacebookGroupBadge = !!__CONFIG__.pages.facebookGroup;
  const isLoginEnabled = useFeatureIsOn('login');

  return (
    <>
      <AppBar className="navigation-bar" position="static" style={{ minHeight: isLayoutSmall ? '50px' : '112px' }}>
        <Toolbar sx={{ padding: { xs: '', md: '0 100px' } }}>
          {isLayoutSmall ? (
            <NavigationDrawer
              scrollToTop={props.scrollToTop}
              extraIcon={showFacebookGroupBadge ? <FacebookGroupBadge color="#626262" /> : undefined}
            />
          ) : (
            <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center" width="100%">
              <Box style={{ maxWidth: '230px' }}>
                <Logo color={props.desktopHomeHeader ? 'inverse' : 'light'} />
              </Box>
              <Grid item md={7}>
                <Grid container alignItems="center" justifyContent="space-between">
                  <Link className={'link'} to={isLayoutSmall ? '/filter' : '/search'}>
                    {t('navigation.search')}
                  </Link>
                  {__CONFIG__.pages.faq.faqEnabled && (
                    <Link className={'link'} to="/faq">
                      {t('navigation.faq')}
                    </Link>
                  )}
                  <Link className={'link'} to="/about">
                    {t('navigation.about-us')}
                  </Link>
                  {__CONFIG__.pages.blog.blogEnabled && (
                    <a className={'link'} href={t('navigation.blog-link')}>
                      {t('navigation.blog')}
                    </a>
                  )}
                  {__CONFIG__.pages.contact.pageEnabled && (
                    <Link className={'link'} to="/contact">
                      {t('navigation.contact-us')}
                    </Link>
                  )}
                  {!__CONFIG__.pages.contact.pageEnabled && (
                    <a className={'link'} href={__CONFIG__.support.messengerLinkDesktop} target="_blank">
                      {t('navigation.contact-us')}
                    </a>
                  )}

                  {/* Facebook group button  */}
                  {showFacebookGroupBadge && !isLoginEnabled && (
                    <Grid item>
                      <FacebookGroupBadge color="#fff" />
                    </Grid>
                  )}

                  {/* Login and User button */}
                  {isLoginEnabled && (
                    <Grid item>
                      <AuthMenuItem />
                    </Grid>
                  )}

                  {__CONFIG__.i18n.languages.length > 1 && <LanguageSwitcher />}
                </Grid>
              </Grid>
            </Stack>
          )}
        </Toolbar>
      </AppBar>
    </>
  );
};
